import { postDailyReport } from '@/api/reports'
import { getEmployeeByMyoctansId, getTasks } from '@/api/resources'
import Btn from '@/components/forms/Btn'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import Input from '@/components/forms/Input'
import { EquipmentSelect, ProjectWithTaskSelect, TaskSelect } from '@/components/forms/FuzzySelect'
import { INPUT_DATETIME_FMT } from '@/constants'
import globalContext from '@/context'
import { useFilledDailyReport, useReports, useSubmit } from '@/hooks'
import { hoursToTime } from '@/utils'
import { groupBy } from 'lodash-es'
import { DateTime } from 'luxon'
import type { FunctionComponent as FC } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Checkbox from '@/components/forms/Checkbox'
import { BonusesSelect } from '@/components/forms/MultiSelect'


type DailyTaskForm = {
	id: number | undefined,
	project_id: number | undefined,
	created_by: number | undefined,
	drilling_block: string,
	ditch_number: string,
	date: DateTime,
	timeEntries: TaskEntry[],
	productionReports: Api.Report[],
	equipment: EquipmentEntry[],
	comment: string,
	gen_mode: string,
}

type EquipmentEntry = {
	[key: string]: number | string | undefined
	equipment_id: number | undefined,
	quantity: number | undefined,
	start_time: number | undefined,
	end_time: number | undefined,
	fuel: number | undefined,
	comment: string
}

const defaultEquipmentEntry: EquipmentEntry = {
	equipment_id: undefined,
	quantity: 0,
	start_time: 0,
	end_time: 0,
	fuel: undefined,
	comment: ''
}

type TaskEntry = {
	[key: string]: number | string
	id: number,
	task_id: number,
	myoctans_task_id: number,
	BT: string,
	work_order_time: number,
	traveling_time: number,
	time: number,
	pay_time: number
}

type Props = {
	path: string
	report_id?: number
	disabled?: boolean
}

const DailyTasks: FC<Props> = (p) => {
	const { t } = useTranslation('daily_tasks')
	const queryClient = useQueryClient()
	const [state, actions] = useContext(globalContext)
	const filledDailyReport = useFilledDailyReport(p.report_id)
	const { data: reports } = useReports()
	const { data: tasks } = useQuery({
		queryKey: ['tasks'],
		queryFn: getTasks,
	})
	const [employee, setEmployee] = useState<Api.Employee>()
	const [dailyReport, setDailyReport] = useState<DailyTaskForm>({
		id: undefined,
		project_id: undefined,
		created_by: state?.user?.id,
		drilling_block: '',
		ditch_number: '',
		date: DateTime.now(),
		timeEntries: [],
		productionReports: [],
		equipment: [],
		comment: '',
		gen_mode: 'simplified',
	})

	useEffect(() => {
		if (!filledDailyReport || !tasks) return
		const timeEntries = filledDailyReport?.time_entries?.map((entry: any) => {
			const task = tasks?.find(task => task.myoctans_task_id == entry.task.myoctans_task_id) ?? {}
			return {
				id: entry.id,
				task_id: entry.task_id,
				myoctans_task_id: entry.task.myoctans_task_id,
				BT: task.BT,
				work_order_time: entry.work_order_time / 60,
				traveling_time: entry.traveling_time / 60,
			}
		}) ?? []
		const equipments = filledDailyReport?.equipment?.map((equipment: any) => {
			return {
				equipment_id: equipment.id,
				quantity: equipment.pivot.quantity,
				start_time: equipment.pivot.start_time,
				end_time: equipment.pivot.end_time,
				fuel: equipment.pivot.fuel,
				comment: equipment.pivot.comment
			}
		})
		const productionReports = filledDailyReport?.forms?.map((report: any) => {
			return {
				...report,
				checked: true
			}
		})

		const newDailyReport: DailyTaskForm = {
			id: filledDailyReport?.id,
			project_id: filledDailyReport?.project_id,
			drilling_block: filledDailyReport?.drilling_block,
			ditch_number: filledDailyReport?.ditch_number,
			date: DateTime.fromISO(filledDailyReport?.date),
			timeEntries: timeEntries,
			equipment: equipments,
			created_by: filledDailyReport?.created_by,
			productionReports: productionReports,
			comment: filledDailyReport?.comment ?? '',
			gen_mode: filledDailyReport?.gen_mode ?? 'simplified',
		}
		setDailyReport(newDailyReport)
	}, [filledDailyReport, tasks])

	useEffect(() => {
		if (filledDailyReport) return
		const { reports } = getFilteredReports(dailyReport.project_id as number, dailyReport.date)
		setDailyReport({ ...dailyReport, productionReports: reports })
	}, [reports])

	const prefetchTasks = async () => {
		await queryClient.prefetchQuery({
			queryKey: ['tasks'],
			queryFn: getTasks
		})
	}

	useEffect(() => {
		prefetchTasks()
	}, [])

	const { setBlob: setEmployeeSignature, append: appendEmployeeSignature } = useDrawing(
		'employee_signature',
		'employee_signature.png'
	)
	const { setBlob: setSupervisorSignature, append: appendSupervisorSignature } = useDrawing(
		'supervisor_signature',
		'supervisor_signature.png'
	)
	const type = 'daily_tasks'

	const { handleSubmit, submitDisabled } = useSubmit({
		async process(fd: FormData) {
			appendEmployeeSignature(fd)
			appendSupervisorSignature(fd)

			if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
		},
		submit: postDailyReport,
		async onSuccess(res) {
			actions.toastInfo(t('toasts:form_submitted'));
			setTimeout(() => {
				history.pushState({}, '', '/reports');
				location.href = '/reports';
			}, 1000);
		}
	})

	useEffect(() => {
		if (state?.user?.id) {
			getEmployeeByMyoctansId(state?.user?.id).then((employee: Api.Employee) => {
				setEmployee(employee)
			})
		}
	}, [])

	const handleProjectMatch = (project: object | undefined) => {
		if (!project || filledDailyReport) return
		const projectMatch = project as Api.Project
		const timeEntries = projectMatch?.tasks ? projectMatch.tasks.filter(task => !task.is_archived).map(task => {
			return {
				id: task.id,
				task_id: task.id,
				myoctans_task_id: task.myoctans_task_id,
				BT: task.BT,
				work_order_time: 0,
				traveling_time: 0,
				time: 0,
				pay_time: 0
			}
		}) : []
		const { reports } = getFilteredReports(projectMatch.id as number, dailyReport.date)
		setDailyReport({ ...dailyReport, project_id: projectMatch?.id, timeEntries: timeEntries, productionReports: reports })
	}

	const handleDateInChange = (e: Event) => {
		const input = e.target as HTMLInputElement
		const date = DateTime.fromISO(input.value)
		const { reports} = getFilteredReports(dailyReport.project_id as number, date)
		setDailyReport({ ...dailyReport, date: date, productionReports: reports })
	}

	const handleTaskInput = (e: Event) => {
		const input = e.target as HTMLInputElement
		const task_id = parseInt(input.name.split('[')[1].split(']')[0])
		const field = input.name.split('[')[2].split(']')[0]

		const updatedTimeEntries = dailyReport?.timeEntries.map(entry => {
			if (entry.task_id === task_id) {
				return { ...entry, [field]: parseFloat(input.value) };
			}
			return entry;
		});
		setDailyReport({ ...dailyReport, timeEntries: updatedTimeEntries });
	}

	const calculateTimes = () => {
		const groupedTimeEntries = dailyReport?.timeEntries.reduce((acc: { [key: string]: number }, entry) => {
			const category = entry.BT;
			if (!acc[category]) {
				acc[category] = 0;
			}
			acc[category] += entry.work_order_time;
			return acc;
		}, {});

		const formattedGroupedTimeEntries = Object.entries(groupedTimeEntries).reduce((acc: { [key: string]: string }, [category, time]) => {
			acc[category] = hoursToTime(time);
			return acc;
		}, {});

		const taskTime = dailyReport?.timeEntries.reduce((acc, entry) => acc + entry.work_order_time, 0)
		return { taskTime: hoursToTime(taskTime), groupedTimeEntries: formattedGroupedTimeEntries }
	}
	
	const handleReportCheck = (index: number) => {
		const updatedProductionReports = dailyReport?.productionReports.map((report, i) => {
			if (i === index) {
				return { ...report, checked: !report.checked }
			}
			return report
		})

		let updatedTimeEntries = dailyReport?.timeEntries
		const checkedReports = updatedProductionReports.forEach((report, i) => {
			if (i !== index) return
			updatedTimeEntries = updatedTimeEntries.map(entry => {
				const task_id = entry.myoctans_task_id
				const timeEntry = report?.form?.time_entries.find(timeEntry => timeEntry.myoctans_task_id == task_id)
				if (timeEntry) {
					entry.work_order_time = (report.checked ? parseFloat(timeEntry.work_order_time as any) : -parseFloat(timeEntry.work_order_time as any)) + parseFloat(entry.work_order_time as any)
					entry.traveling_time = (report.checked ? parseFloat(timeEntry.traveling_time as any) : -parseFloat(timeEntry.traveling_time as any)) + parseFloat(entry.traveling_time as any)
				}
				return entry
			})
		})

		setDailyReport({ ...dailyReport, productionReports: updatedProductionReports, timeEntries: updatedTimeEntries })		
	}

	const { taskTime, groupedTimeEntries } = calculateTimes()

	const getFilteredReports = (projectId: number, date: DateTime) => {
		let filledDrillerProdReports = reports?.filter(report => report.form_type === 'driller_prod')
									.filter(report => report.project_id == projectId)
									.filter(report => DateTime.fromISO(report.date_in).toISODate() === date.toISODate() ||
												DateTime.fromISO(report.date_in).toISODate() === date.plus({days: 1}).toISODate() ||
												DateTime.fromISO(report.date_in).toISODate() === date.minus({days: 1}).toISODate())
									.filter(report => report.daily_reports.length == 0) ?? []

		filledDrillerProdReports = filledDrillerProdReports.map(report => {
			return {
				...report,
				checked: false
			}
		})

		return { reports: filledDrillerProdReports }
	}

	
	const timeToAllocate = dailyReport.productionReports?.reduce((acc, report) => {
		if (report.checked) {
			return acc + report.form.time_entries?.reduce((acc: number, entry: any) =>  acc + parseFloat(entry.work_order_time), 0)
		}
		return acc
	}, 0)

	const bonusIndex = dailyReport?.timeEntries.find(entry => entry.work_order_time !== 0)?.task_id ?? 0

	return (
		<>
			<h1>{t('title')}</h1>
			<form onSubmit={handleSubmit}>
				<fieldset className='dummy' disabled={p.disabled}>
					<input type="hidden" name="id" value={dailyReport?.id} />
					<input type="hidden" name="gen_mode" value={'simplified'} />
					<input type="hidden" name="created_by" value={employee?.user?.id} />
					<input type="hidden" name="date" value={dailyReport?.date?.toISODate()} />
					<ProjectWithTaskSelect
						required
						name='project_id'
						defaultValue={filledDailyReport ? dailyReport?.project_id?.toString() : undefined}
						label={t('project')}
						onMatch={handleProjectMatch}
					/>
					<Input
						className='span6'
						required
						max={DateTime.now().toFormat(INPUT_DATETIME_FMT)}
						defaultValue={dailyReport?.date?.toFormat(INPUT_DATETIME_FMT)}
						type='datetime-local'
						label={t('date_in')}
						onBlur={handleDateInChange}
					/>
					<Input
						className='span6'
						type='text'
						name='drilling_block'
						defaultValue={dailyReport?.drilling_block}
						label={t('drilling_block')}
					/>
					<Input
						className='span6'
						type='text'
						name='ditch_number'
						defaultValue={dailyReport?.ditch_number}
						label={t('ditch_number')}
					/>

					{
						dailyReport?.productionReports.length > 0 ? (
							<fieldset>
								<legend>{t('production_reports')}</legend>
								{dailyReport?.productionReports.map((report, i) => (
									<>
										{report.checked && (
											<input type="hidden" name={`related_forms[]`} value={report.id} />
										)}
										<Checkbox
											class=''
											label={DateTime.fromISO(report.date_in).toFormat('yyyy-MM-dd hh:mm a') + ' - ' + report.form.drilling_block}
											checked={report.checked}
											onChange={() => handleReportCheck(i)}
										/>
									</>
								))}
							</fieldset>
						) : ''
					}

					<Input
						className='span6'
						type='text'
						label={t('total_report_hours')}
						value={timeToAllocate}
						readOnly
					/>
					<fieldset>
						<legend>{t('time_entries')}</legend>
						{dailyReport?.timeEntries ? Object.entries(groupBy(dailyReport.timeEntries, 'BT')).map(([category, tasks]) => (
							<fieldset>
								<legend>{category}</legend>
								{tasks.map(task => (
									<>
										<input type="hidden" name={`time_entries[${task.id}][time]`} value={task.work_order_time} />
										<input type="hidden" name={`time_entries[${task.id}][pay_time]`} value={task.work_order_time} />
										<input type="hidden" name={`time_entries[${task.id}][billing_traveling_time]`} value={task.traveling_time} />
										<input type="hidden" name={`time_entries[${task.id}][payable_traveling_time]`} value={task.traveling_time} />
										<input type="hidden" name={`time_entries[${task.id}][task_id]`} value={task.id} />
										<input type="hidden" name={`time_entries[${task.id}][employee_id]`} value={employee?.id} />
										<input type="hidden" name={`time_entries[${task.id}][time_in]`} value={dailyReport.date.toFormat('HH:mm')} />
										<input type="hidden" name={`time_entries[${task.id}][myoctans_type]`} value={'daily_tasks'} />
										<TaskSelect
											required
											name={`time_entries[${task.id}][myoctans_task_id]`}
											selectedId={task.myoctans_task_id as number}
											label={t('task')}
											readOnly={true}
											className={'span2'}
										/>

										<Input
											label={t('hours')}
											className='span2'
											name={`time_entries[${task.id}][work_order_time]`}
											value={task.work_order_time}
											onBlur={handleTaskInput}
											decimal
										/>
										<Input
											label={t('traveling')}
											className='span2'
											name={`time_entries[${task.id}][traveling_time]`}
											value={task.traveling_time}
											onBlur={handleTaskInput}
											decimal
										/>
									</>
								))}
							</fieldset>
						)) :
							<p class="text-center">{t('select_a_project')}</p>
						}
					</fieldset>

					<fieldset>
						<legend>{t('summary')}</legend>
						{
							groupedTimeEntries ? Object.entries(groupedTimeEntries).map(([category, time]) => (
								parseFloat(time) > 0 && (
									<Input
										className='span6'
										label={category}
										type={'text'}
										disabled
										value={time.toString()}
									/>
								)
							)) : ''
						}
						<Input
							className='span6'
							label={t('task_time')}
							type={'text'}
							disabled
							value={taskTime.toString()}
						/>
						<Input
							className='span6'
							label={t('production_report_hours')}
							type={'text'}
							disabled
							value={hoursToTime(timeToAllocate)}
						/>
					</fieldset>

					<fieldset>
						<legend>{t('equipments')}</legend>
						<div class='flex flex-col gap-y-8'>
							{dailyReport.equipment.map((equipment, i) =>
								<fieldset key={i}>
									<input type="hidden" name={`equipment[${i}][duration]`} value={(equipment.end_time ?? 0) - (equipment.start_time ?? 0)} />
									<EquipmentSelect
										className='span4'
										required
										name={`equipment[${i}][equipment_id]`}
										defaultValue={equipment.equipment_id?.toString()}
										label={t('name')}
										filterkey={'forms'}
									/>
									<Input
										className='span2'
										required
										integer
										value={equipment.quantity}
										name={`equipment[${i}][quantity]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].quantity = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('quantity')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.start_time}
										name={`equipment[${i}][start_time]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].start_time = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('start_time')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.end_time}
										name={`equipment[${i}][end_time]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].end_time = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('end_time')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.fuel}
										name={`equipment[${i}][fuel]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].fuel = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('fuel')}
									/>
									<Input
										className='span6'
										type='text'
										value={equipment.comment}
										name={`equipment[${i}][comment]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].comment = (event.target as HTMLInputElement)?.value;
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('comments')}
									/>

								</fieldset>
							)}
							<div class='flex justify-end gap-x-2'>
								{dailyReport.equipment.length === 0 ? '' :
									<Btn danger onClick={() => setDailyReport({ ...dailyReport, equipment: dailyReport.equipment.slice(0, -1) })}>
										{t('forms:remove')}
									</Btn>
								}
								<Btn onClick={() => setDailyReport({ ...dailyReport, equipment: [...dailyReport.equipment, JSON.parse(JSON.stringify(defaultEquipmentEntry))] })}>
									{t('forms:add')}
								</Btn>

							</div>
						</div>
					</fieldset>
					<br />
					<label>
						{t('bonuses')}
						<BonusesSelect
							name={'time_entries[' + bonusIndex + '][bonuses][]'}
							defaultValue={filledDailyReport ? filledDailyReport.time_entries.find(entry => entry.bonuses.length != 0)?.bonuses : []}
						/>
					</label>
					<br />
					<Comments name='comment' label={t('comments')} />
					<Drawing label={t('employee_signature')} setBlob={setEmployeeSignature} />
					<Drawing label={t('supervisor_signature')} setBlob={setSupervisorSignature} />
					<Btn disabled={submitDisabled} submit>
						{t('forms:submit')}
					</Btn>
				</fieldset>
			</form>
		</>


	)
}
export default DailyTasks
